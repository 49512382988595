import { ComponentType, useEffect, useState } from 'react';
import { FacebookProvider } from 'react-facebook';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { StyleProvider } from '@ant-design/cssinjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import queryString from 'query-string';

import MainLayout from 'src/components/Layout/MainLayout';
import { Web3ContextProvider } from 'src/context/web3';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { wrapper } from 'src/redux/store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/styles/index.less';

export type NextPageWithLayout = NextPage & {
	getLayout?: ComponentType<any>;
	layoutProps?: any;
};

export let noIndexNoFollow = { noindex: false, nofollow: false };
if (process.env.NEXT_PUBLIC_FLAVOR !== 'MAINNET') {
	noIndexNoFollow = { noindex: true, nofollow: true };
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const AppRoot = ({ Component, pageProps }: AppPropsWithLayout) => {
	const router = useRouter();
	const { user } = useAuthReducer();

	const [queryClient] = useState(
		() => new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 } } }),
	);

	const Layout = Component.getLayout ?? MainLayout;
	const layoutProps = Component.layoutProps;

	useEffect(() => {
		if (!router.isReady) return;

		if (!user && pageProps?.protectedRoute) {
			router.push('/');
		}
	}, [JSON.stringify(user), router.isReady]);

	const { url } = queryString.parseUrl(router.asPath);

	return (
		<QueryClientProvider client={queryClient}>
			<Hydrate state={pageProps.dehydratedState}>
				<Web3ContextProvider>
					<GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}>
						<FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string}>
							<StyleProvider layer>
								<Layout {...pageProps} {...layoutProps} router={router}>
									<NextSeo
										{...noIndexNoFollow}
										canonical={process.env.NEXT_PUBLIC_WEB_URL + url}
										title="CREOPLAY"
										description="creoplay.app"
									/>
									<Head>
										<meta
											name="viewport"
											// eslint-disable-next-line max-len
											content="width=device-width, minimum-scale=1, maximum-scale=3, initial-scale=1, shrink-to-fit=no, height=device-height, viewport-fit=cover"
										/>
									</Head>
									<Component {...pageProps} router={router} />
								</Layout>
							</StyleProvider>
						</FacebookProvider>
					</GoogleOAuthProvider>
				</Web3ContextProvider>
			</Hydrate>
		</QueryClientProvider>
	);
};

export default wrapper.withRedux(AppRoot);
