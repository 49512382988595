import creoAbi from './web3json/creoabi';
import marketplaceAbi from './web3json/marketplaceabi';
import nftbulkAbi from './web3json/nftbulkabi';
import stakingAbi from './web3json/stakingabi';
import storeAbi from './web3json/storeabi';
import subscriptionabi from './web3json/subscriptionabi';
import swapAbi from './web3json/swapabi';
import creoAbiTestnet from './web3json/testnet/creoabi';
import stakingAbiTestnet from './web3json/testnet/stakingabi';

const web3config =
	process.env.NEXT_PUBLIC_FLAVOR === 'MAINNET'
		? {
				infuraId: '460f40a260564ac4a4f4b3fffb032dad',
				projectId: 'dbbc3c643b1dd798674125d1a51777ba',

				creoAddress: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
				creoAbi: JSON.stringify(creoAbi),
				stakingAddress: '0xf5a7224602F32ceC97Bc75895C6D58DE9C469b59',
				stakingAbi: JSON.stringify(stakingAbi),
				creoMainnetAddress: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
				marketplaceAbi: JSON.stringify(marketplaceAbi),
				marketplaceAddress: '0xF52fd1E67c182543CF94cA599cFa8148EaBEF1Bd',
				nftCharacterBulkAddress: '0xd15290064c1dc062d7c8146b23dee0c394a7ad62',
				nftWeaponBulkAddress: '0xD0b67789293F26206096847eEfbE2c442D1E652A',
				nftBulkAbi: JSON.stringify(nftbulkAbi),
				swapAddress: '0x492761e4D5476A84e7b20538B808E10f6BAb17AC',
				swapAbi: JSON.stringify(swapAbi),
				storeAddress: '0xB42740dF0a91a1Bb9Aa1242527651A8D63990Ec1',
				storeAbi: JSON.stringify(storeAbi),
				pancakeswapUrl: 'https://api.coingecko.com/api/v3/coins/creo-engine',
				usdtAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
				subscriptionAddress: '0x180703F3008368588FE2804783b97Cb773911285',
				subscriptionAbi: JSON.stringify(subscriptionabi),
			}
		: {
				infuraId: '460f40a260564ac4a4f4b3fffb032dad',
				projectId: 'dbbc3c643b1dd798674125d1a51777ba',
				creoAddress: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
				creoAbi: JSON.stringify(creoAbiTestnet),
				stakingAddress: '0xf5a7224602F32ceC97Bc75895C6D58DE9C469b59',
				stakingAbi: JSON.stringify(stakingAbiTestnet),
				creoMainnetAddress: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
				marketplaceAbi: JSON.stringify(marketplaceAbi),
				marketplaceAddress: '0xF52fd1E67c182543CF94cA599cFa8148EaBEF1Bd',
				nftCharacterBulkAddress: '0xd15290064c1dc062d7c8146b23dee0c394a7ad62',
				nftWeaponBulkAddress: '0xD0b67789293F26206096847eEfbE2c442D1E652A',
				nftBulkAbi: JSON.stringify(nftbulkAbi),
				swapAddress: '0x492761e4D5476A84e7b20538B808E10f6BAb17AC',
				swapAbi: JSON.stringify(swapAbi),
				storeAddress: '0xB42740dF0a91a1Bb9Aa1242527651A8D63990Ec1',
				storeAbi: JSON.stringify(storeAbi),
				pancakeswapUrl: 'https://api.coingecko.com/api/v3/coins/creo-engine',
				usdtAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
				subscriptionAddress: '0x180703F3008368588FE2804783b97Cb773911285',
				subscriptionAbi: JSON.stringify(subscriptionabi),
			};

export default web3config;
